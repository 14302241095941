body {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans', sans-serif;
}

.MuiTablePagination-toolbar > p {
  margin: 0;
}

::-webkit-scrollbar {
  width: 0.8rem
}

::-webkit-scrollbar-track {
  outline: 1px solid lightgrey ;
  border-radius: 0.3rem  ;
}
::-webkit-scrollbar-thumb {
  background-color: #ced4da;
  /* outline: 1px solid slategrey; */
  border-radius: 0.3rem  ;
  height: 5rem;
}


.scoreIndicator{
  height: 15px;
  width: 15px;
  border-radius: 15px;
  margin: 0 6px 0 18px;
}

.redScore{
  background: rgb(232, 35, 35);
}

.yellowScore {
  background: #FFBF00;
}
.greenScore {
  background: green;
  /* background: rgba(39, 140, 39, 0.81); */
}
.activeQueue {
    background: lightgrey;
}

.table-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selected-Queue {
    margin: 0;
    height: 2rem;
    font-size: 1.2rem;
}

.live-count {
    border-radius: 50%;
    color: white;
    font-weight: 600;
    height: 16px;
    width: 16px;
    background-color: #23395d;
    display: flex;
    align-items: center;
    justify-content: center;
}

.negativeCount {
    /* background-color: #e35744; */
    background-color: red;

}

.rttSupervisorActions {
    display: flex;
    gap: 20px;
    justify-content: center;
}


.transcriptMdlTitle {
    margin: 0;
}

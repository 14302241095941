.agentChatView{
    height: 98vh;
    width: 100%;
    margin:auto;
    margin-top: 10px;
    border: 1px solid rgb(200, 196, 196);
    /* min-width: 420px; */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    
}

.agentViewTitle {
    outline: 1px solid rgb(200, 196, 196);
    padding: 2rem 1rem;
    box-sizing: border-box;
    height: 7%;
    margin: 0;
    display: flex;
    align-items: center;
    font-weight: 600;
    background-color:#34435e;
    color: white;
}

.agentViewTitle > h4 {
    margin: 0;
}
.agentviewContent {
    height: 88%;
    box-sizing: border-box;
    overflow: auto;
}

.agentViewButton {
  height: 5%;
  border: 1px solid lightgrey;
  display: flex;
  padding: 3px;
  align-items: center;
}
.chat-box {
    border-radius: 10px;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
    padding: 1.5rem;
}
  
.messageContaineragent{
  float: right;
  min-width: 20%;
  max-width: 65%;
  clear: both;
}

.messageContainercustomer{
  min-width: 20%;
  max-width: 65%;
  clear: both;
}

.messageLabel{
  color: grey;
  margin: 0;
}

  .customer, .agent {
    display: block;
    /* min-width: 20%;
    max-width: 65%; */
    padding: 0.5rem;
    border-radius: 0px 10px 10px 10px;
    clear: both;
    background: #dee2e642;
    position: relative;
  }

  /* .customer::after{
    content: 'Customer';
    position: absolute;
    top: -23px;
    left: 0pc;
    font-size: 1rem;
    color: grey;
  }

  .agent::after{
    content: 'Agent';
    position: absolute;
    top: -23px;
    left: 0pc;
    font-size: 1rem;
    color: grey;
  } */

  .agent{
    border-radius: 10px 0px 10px 10px;
  }
  
  /* .agent, .agent-label {
    float: right;
  } */
  
  .red{
    background: #f8455670;
  }

  .green{
    background: rgb(203 243 203);
  }